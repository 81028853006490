@import "utils/mixins";


.logo {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  background: #001529;

  &:hover {
    color: #ffffff;
  }

  @include respond-above(md) {
    position: relative;
    left: -210px;
    width: 200px;
    border-bottom: 1px solid #4b4b4b;
    height: 64px;
  }
}

.slider {
  display: block;
  padding-top: 60px;
  @media (max-width: 990px) {
    display: none;
  }

  .ant-menu-item-divider {
    border-color: #4b4b4b;
  }
}

.site-layout{
  margin-left: 200px;
  @media (max-width: 990px) {
    margin-left: 0;
  }
}

.site-layout-background{
  position: sticky;
  top: 0;
  z-index: 9;
}

.menu-button{
  margin-right: 10px;

  @include respond-above(md) {
    display: none;
  }
}