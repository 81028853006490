.card {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	margin-bottom: 8px;

	@media (max-width: 990px) {
		margin-left: 0;
	}
}

.photos {
	overflow: auto;
	width: 100%;
	display: flex;
	gap: 8px;
	padding-bottom: 8px;
}

.item {
	padding: 3px;
	position: relative;
	line-height: 0;

	img {
		border-radius: 16px;
	}
}