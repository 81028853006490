.wrpper {
	overflow: auto;
	padding: 10px;
	height: 100%;
	// max-height: calc(100vh - 180px);
	// [class*='ant-row ant-form-item'] {
	// 	margin-bottom: 13px;
	// }
}
// .modalStyle {
// 	// width: 740px !important;
// 	height: 100%;
// 	padding: 16px 0;
// 	[class*='ant-modal-content'] {
// 		height: 100%;
// 		display: flex;
// 		flex-direction: column;
// 	}
// 	[class*='ant-modal-body'] {
// 		height: 100%;
// 		overflow: auto;
// 	}
// }
.modalStyle {
	overflow: auto;
	padding: 10px;
	width: 740px !important;
	[class*='ant-row ant-form-item'] {
		margin-bottom: 13px;
	}
}
.styleButton {
	color: white;
	background: #363b45;
	border-color: #363b45;
	&:active,
	&:focus,
	&:hover {
		color: white;
		background: #363b45;
		border-color: #363b45;
		opacity: 0.9;
	}
}
.wrapperEmpty {
	display: flex;
	justify-content: center;
	height: 100%;
	align-items: center;
}
.wraapperAddFilling {
}
.wrapperFormMain {
	max-width: 1000px;
}
.wrapperBannersAdd {
	display: flex;
	flex-direction: column;
	[class*='ant-upload-select']{
		width: 100%;
	}
	.span {
		font-family: Roboto;
		font-size: 12px;
		color: rgba(0, 0, 0, 0.45);
		margin-left: 135px;
	}
	.button {
		width: fit-content;
		color: white;
		background: #363b45;
		border-color: #363b45;
		&[disabled] {
			color: rgba(0, 0, 0, 0.25);
			border-color: #d9d9d9;
			background: #f5f5f5;
			text-shadow: none;
			box-shadow: none;
			&:active,
			&:focus,
			&:hover {
				color: rgba(0, 0, 0, 0.25);
				border-color: #d9d9d9;
				background: #f5f5f5;
				text-shadow: none;
				box-shadow: none;
			}
		}
		&:active,
		&:focus,
		&:hover {
			color: white;
			background: #363b45;
			border-color: #363b45;
			opacity: 0.9;
		}
	}
}
.transfer {
	width: 100%;
	[class='ant-transfer-list'] {
		width: 100%;
	}
	[class='ant-transfer-list-header'] {
		background: #f9f9f9;
	}
	[class='ant-transfer-list-header-selected'] {
		width: 100%;
		margin-right: 0;
	}
}
.selectAllLabels {
	font-family: Roboto;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.85);
	display: flex;
	justify-content: space-between;
	span {
		font-weight: 500;
		color: #a9abaf;
	}
}
.wrapperContentBanners {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}
.wrapperCurBanner {
	display: flex;
	width: 200px;
	height: 200px;
	flex-direction: column;
	background: #ffffff;
	border: 1px solid #d8d8d8;
	box-sizing: border-box;
	border-radius: 2px;
	margin: 7px;
	padding: 10px;
	cursor: pointer;
	transition: border 0.3s, background 0.3s, opacity 0.3s;
	&:hover {
		border-color: var(--ant-primary-color);
	}
	img {
		object-fit: contain;
		height: 100px;
	}
}
.disabledBanner{
	background: #cdcdcd;
    opacity: 0.5;
}
.wrapperCurBannerActive{
	border-color: var(--ant-primary-color);
}
.titleBanner {
	font-family: Roboto;
	font-size: 14px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.85);
}
.urlBanner {
	font-family: Roboto;
	font-size: 12px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.45);
}
.actionBanner {
	width: 100%;
	display: flex;
	border-top: 1px solid #f0f0f0;
	margin-top: 3px;
	button:first-child {
		border-right: 1px solid #f0f0f0;
	}
	button {
		margin-top: 3px;
		flex: 1;
	}
}
.radioBanner{
	position: absolute;
}
.wrapperSearch{
	display: flex;
	button{
		margin-left: 7px;
	}
}

.switchStyle {
	display: flex;
	span {
        align-items: center;
        display: flex;
		margin-left: 10px;
		color: #72767d;
		font-size: 14px;
        margin-bottom: 13px;
	}
}