$breakpoints: (
	xxs: 375px,
	xs: 576px,
	sm: 768px,
	smd: 900px,
	md: 992px,
	lg: 1200px,
	xl: 1400px,
	xxl: 1800px
);

@mixin respond-above($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);
		@media (min-width: $breakpoint-value) {
			@content;
		}
	} @else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

@mixin respond-below($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);
		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}
	} @else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

@mixin respond-between($lower, $upper) {
	@if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
		$lower-breakpoint: map-get($breakpoints, $lower);
		$upper-breakpoint: map-get($breakpoints, $upper);
		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
			@content;
		}
	} @else {
		@if (map-has-key($breakpoints, $lower) == false) {
			@warn 'Your lower breakpoint was invalid: #{$lower}.';
		}
		@if (map-has-key($breakpoints, $upper) == false) {
			@warn 'Your upper breakpoint was invalid: #{$upper}.';
		}
	}
}

@mixin flex-gap($row-gap) {
	gap: $row-gap;
	@supports (-webkit-touch-callout: none) and (not (translate: none)) {
		& > *:nth-child(1n) {
			margin-left: $row-gap;
		}
		& > *:first-child {
			margin-left: 0;
		}
	}
}
