

.table {
	display: block;
	@media (max-width: 990px) {
		display: none;
	}
}

.mobile {
	display: none;
	@media (max-width: 990px) {
		display: flex;
	}
	flex-wrap: wrap;
	gap: 20px;
	&__card{
		&__info{
			display: flex;
			span:first-child{
				display: flex;
				width: 80px;
				font-weight: 600;
			}
		}
		&__actions{
			display: flex;
			gap: 20px;
			margin-top: 5px;
		}
	}
}
