.wrapperUsers {
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: 100%;
}
.contentTab {
	margin: 16px 20px;
	font-family: Roboto;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #363b45;
	display: flex;
	align-items: center;
	padding: 10px 20px;
	border-radius: 3px;
	background: var(--globalColar);
}
.wrapperTable {
	margin: 0 20px;
	padding: 10px 20px;
	border-radius: 3px;
	background: var(--globalColar);
	display: flex;
	flex-direction: column;
	@media (max-width: 990px) {
		padding: 10px 0;
	}
	tr > th,
	tr > td {
		padding: 9px 16px;
	}
}

.wrapperTable2 {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.headerMenu {
	display: flex;
	margin-left: auto;
	align-items: center;
	padding: 8px 16px;
	width: auto;
	height: 40px;
}
.editButton {
	display: flex;
	justify-content: space-around;
	&:first-child {
		svg {
			color: var(--ant-primary-color);
		}
	}
	svg {
		color: silver;
	}
}
.margin {
	margin: 0 5px;
	min-width: 120px;
}
.silver {
	color: silver;
}
.silver2 {
	color: #848484;
}
.wrapperChart {
	height: 500px;
}
.pagination {
	margin-left: auto;
	padding: 0 10px;
}
.wrapperEmpty {
	display: flex;
	padding: 10px 20px;
	border-radius: 3px;
	background: var(--globalColar);
	height: 100%;
	margin: 16px 20px;
	align-items: center;
	justify-content: center;
}

.customTooltip {
	display: flex;
	flex-direction: column;
	background: white;
	padding: 10px;
}

.loading{
	opacity: 0.4;
	pointer-events: none;
	transition: opacity .3s;
}