@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

body {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
}

/* #root {
  height: 100%;
  display: flex;
} */

.ant-page-header-heading-title {
  text-transform: uppercase;
  font-weight: 700;
}

.ant-page-header {
  margin-bottom: 20px;
  padding: 0;
}

.ant-layout {
  background-color: #fff;
  min-height: 100vh;
}

.ant-layout-footer {
  margin-top: 56px;
}
