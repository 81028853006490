.wrapperRecords {
	display: flex;
	flex-direction: column;
	overflow: auto;
	
	height: 100%;
}
.contentTab {
	margin-right: 20px;
	margin-bottom: 20px;
	font-family: Roboto;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #363b45;
	display: flex;
	align-items: center;
	border-radius: 3px;
	background: var(--globalColar);
}
.wrapperTable {
	margin-right: 20px;
	// margin: 0 20px;
	tr > th,
	tr > td {
		padding: 9px 16px;
	}
}
.addUsers {
	display: flex;
	margin-left: auto;
	align-items: center;
	padding: 8px 16px;
	width: auto;
	height: 40px;
	background: #f9f9f9;
	border: 1px dashed #d9d9d9;
	border-radius: 2px;
	cursor: pointer;
	transition: opacity 0.3s;
	&:hover {
		opacity: 0.8;
	}
	span {
		font-weight: 500;
		font-size: 14px;
		color: #363b45;
		margin-left: 10px;
	}
}
.editButton {
	display: flex;
    justify-content: space-around;
	&:first-child {
		svg{
			color: var(--ant-primary-color);
		}
	}
	svg {
		color: silver ;
	}
}

.button{
	margin: 4px 0;
	margin-left: auto;
	height: 100%;
		color: white;
		font-family: Roboto;
		line-height: 24px;
		background: #f9f9f9;
		color: #363b45;
		border-color: #d9d9d9;
		&[disabled] {
			color: rgba(0, 0, 0, 0.25);
			border-color: #d9d9d9;
			background: #f5f5f5;
			text-shadow: none;
			box-shadow: none;
			&:active,
			&:focus,
			&:hover {
				color: rgba(0, 0, 0, 0.25);
				border-color: #d9d9d9;
				background: #f5f5f5;
				text-shadow: none;
				box-shadow: none;
			}
		}
		&:active,
		&:focus,
		&:hover {
			background: #f9f9f9;
			color: #363b45;
			border-color: #d9d9d9;
			opacity: 0.9;
		}
}