@import "utils/mixins";

.textArea {
	width: 100%;
	min-width: 300px;
	@include respond-below(md) {
		min-width: 600px;
	}
	@include respond-below(sm) {
		min-width: 400px;
	}
	@include respond-below(xs) {
		min-width: 300px;
	}
}
